import React, { FC, useState, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '../authUiComponents/Button';
import Message from '../authUiComponents/Message';
import { Theme, Grid, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { sendPasswordResetEmail, setError, setSuccess } from '../../../store/actions/authActions';
import { RootState } from '../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        marginTop: '200px',
    },
    titleContainer: {
        marginBottom: '35px',
    },
    title: {
        fontSize: '25px',
    },
      form: {
        maxWidth: "600px",
        margin: "0 auto",
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#77b8bd',
        color: '#fff',
        width: '100%',
        marginBottom: '30px',
      },
  }));

const ForgotPassword: FC = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { error, success } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        return () => {
            if (error) {
                dispatch(setError(''));
            }
            if (success) {
                dispatch(setSuccess(''));
            }
        }
    }, [error, dispatch, success]);

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(sendPasswordResetEmail(email, "Email sent!"));
        setLoading(false);
    }

    return (
        <Grid container className={classes.container} direction="column" justifyContent="center">
            <Grid container className={classes.titleContainer} justifyContent="center">
                <Grid item>
                <Typography className={classes.title}>Reset Password</Typography>
                </Grid>
            </Grid>
            <Grid item>
            <form className={classes.form} onSubmit={submitHandler}>
                    {error && <Message type="danger" msg={error} />}
                    {success && <Message type="success" msg={success} />}
                       <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            type="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            placeholder="Email address"
                            autoComplete="email"
                    />
                    <Button 
                        type="submit"
                        className={classes.submit}
                        text={loading ? "Loading..." : "Send password reset email"}
                        disabled={loading}
                    />
                </form>
            </Grid>
        </Grid>
    );
}

export default ForgotPassword;