import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { signout } from "../../store/actions/authActions";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Theme,
  ListItem,
  ListItemText,
  List,
  IconButton,
  SwipeableDrawer,
  Menu,
  Button,
  Tab,
  Tabs,
  useScrollTrigger,
  Toolbar,
  AppBar,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { animateScroll as scroll } from "react-scroll";
import logo from "../logo.png";
import IsMobileView from "src/utils/isMobileView";

function ElevationScroll(props: any) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarMargin: {},
    toolbar: {
      maxHeight: "520px",
    },
    logo: {
       height: "8.5em",
    },
    logoContainer: {
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    tabContainer: {
      margin: "auto",
    },
    tab: {
      ...theme.typography.caption,
      minWidth: 10,
      marginLeft: "25px",
      "&:hover": {
        color: "#77b8bd",
      },
      fontWeight: "bold",
      fontSize: "16px",
    },
    button: {
      ...theme.typography.button,
      borderRadius: "50px",
      marginRight: "25px",
    },
    menu: {
      backgroundColor: theme.palette.common.white,
      color: "#77b8bd",
      borderRadius: "0px",
    },
    menuItem: {
      ...theme.typography.caption,
      opacity: 0.7,
      "&:hover": {
        opacity: 1,
      },
    },
    drawerIconContainer: {
      marginLeft: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    drawerIcon: {
      height: "50px",
      width: "50px",
      color: "#77b8bd",
    },
    drawer: {
      backgroundColor: theme.palette.common.black,
      marginTop: "105px",
    },
    drawerItem: {
      ...theme.typography.caption,
      color: "#FFF",
      opacity: 0.7,
      "&:hover": {
        color: "#77b8bd",
      },
      fontSize: '24px',
    },
    drawerItemEstimate: {
      backgroundColor: theme.palette.common.black,
    },
    drawerItemSelected: {
      "& .MuiListItemText-root": {
        opacity: 1,
      },
    },
    appbar: {
      zIndex: theme.zIndex.modal + 1,
    },
  })
);

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const classes = useStyles();
  const isBrowser = typeof window !== "undefined";
  const iOS = isBrowser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const { authenticated } = useSelector((state: RootState) => state.auth);

  const logoutClickHandler = () => {
    dispatch(signout());
  };

  const handleChange = (e: any, newValue: any) => {
    scroll.scrollToTop();
    setValue(newValue);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routes = [
    { name: "Home", link: "/", activeIndex: 0 },
    // { name: "Leadership", link: "/leadership", activeIndex: 1 }, 
    { name: "Scrum Master", link: "/scrummaster", activeIndex: 1 },
    { name: "Project Manager", link: "/projectmanager", activeIndex: 2 },
    { name: "Blog", link: "/blog", activeIndex: 3},
    // { name: "About", link: "/about", activeIndex: 4 },
    { name: "Private", link: "/private", activeIndex: 4 },
  ];

  useEffect(() => {
    [...routes].forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
          }
          break;
          case "/signin":
            setValue(5);
            break;
        default:
          break;
      }
    });
  }, [value, routes]);

  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChange}
        className={classes.tabContainer}
        indicatorColor="primary"
        textColor="inherit"
      >
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={classes.tab}
            component={Link}
            to={route.link}
            label={route.name}
          />
        ))}
      </Tabs>
      <React.Fragment>
        {!authenticated ? (
          <React.Fragment>
            {/* <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              component={Link}
              to="/signin"
            >
              Sign In
            </Button> */}

            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              href="https://www.linkedin.com/services/page/0a8a4a31080a313205"
            >
              Contact Us
            </Button>
          </React.Fragment>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={logoutClickHandler}
          >
            Logout
          </Button>
        )}
      </React.Fragment>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        classes={{ paper: classes.menu }}
        elevation={0}
        style={{ zIndex: 1302 }}
        keepMounted
      ></Menu>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        anchor={'right'}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {routes.map((route) => (
            <ListItem
              key={`${route}${route.activeIndex}`}
              divider
              button
              component={Link}
              to={route.link}
              selected={value === route.activeIndex}
              classes={{ selected: classes.drawerItemSelected }}
              onClick={() => {
                setOpenDrawer(false);
                setValue(route.activeIndex);
              }}
            >
              <ListItemText className={classes.drawerItem} disableTypography>
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
          <React.Fragment>
            {!authenticated ? (
              <React.Fragment>
                <ListItem
                  classes={{
                    root: classes.drawerItemEstimate,
                    selected: classes.drawerItemSelected,
                  }}
                  // onClick={() => {
                  //   setOpenDrawer(false);
                  //   setValue(5);
                  // }}
                  divider
                  button
                  // selected={value === 5}
                >
                  <a href="https://www.linkedin.com/services/page/0a8a4a31080a313205"><ListItemText
                    className={classes.drawerItem}
                    disableTypography
                  >
                    Contact Us
                  </ListItemText></a>
                </ListItem>
              </React.Fragment>
            ) : (
              <ListItem
                classes={{
                  root: classes.drawerItemEstimate,
                  selected: classes.drawerItemSelected,
                }}
                onClick={() => {
                  setOpenDrawer(false);
                  logoutClickHandler();
                  setValue(6);
                }}
                divider
                button
                selected={value === 6}
              >
                <ListItemText className={classes.drawerItem} disableTypography>
                  Logout
                </ListItemText>
              </ListItem>
            )}
          </React.Fragment>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
        size="large">
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar disableGutters className={classes.toolbar}>
            <Button
              component={Link}
              to="/"
              onClick={() => setValue(0)}
              className={classes.logoContainer}
              disableRipple
            >
              <img alt="company logo" className={classes.logo} src={logo} />
            </Button>
            {IsMobileView() ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
};

export default Header;
