import React from 'react';
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      footer: {
          height: "25px",
          background: "white",
          padding: "25px",
      },
  }));

interface HeaderProps {}

const Footer: React.FC<HeaderProps> = () => {
    const classes = useStyles();

    return (
        <div className={classes.footer}>OttoSchool 2021</div>
    )
}

export default Footer;
