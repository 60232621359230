import React from "react";
import { Theme, Button, Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Calendly from "./Calendly";
import { FiChevronsDown } from "react-icons/fi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: '15px',
      padding: '10px',
      margin: '10px',
     },
  })
);

interface OneTimeMentoringProps {
  navTo(): void;
}

const OneTimeMentoring: React.FC<OneTimeMentoringProps> = (props) => {
  const { navTo } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
         container
         direction="column"
         justifyContent="center"
         alignItems="center"
         spacing={3}
      >
        <b>Now that you learned your results time to reflect on them with a mentor. Please reserve a slot for <u>FREE</u> as a kick-off action:</b>
        <Grid item>
          <Calendly/>
        </Grid>
        <Grid item>
          <Button className={classes.button} onClick={() => {navTo()}}>Reserve Regular Mentoring<FiChevronsDown/></Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OneTimeMentoring;

