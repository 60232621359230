import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCploq8Jar7mxDtn86il9xJV0c6DvKMZoc",
  authDomain: "ottoschool-3b0d0.firebaseapp.com",
  databaseURL: "https://ottoschool-3b0d0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ottoschool-3b0d0",
  storageBucket: "ottoschool-3b0d0.appspot.com",
  messagingSenderId: "238294492812",
  appId: "1:238294492812:web:d74e6885736f6aed3a7da9"
});

export default firebase;