import { Button, Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import lnkdpic from './Linkedinblackvector.png';
import insta from './instapngegg.png';
import { FiGitCommit, FiChevronsDown, FiChevronDown } from "react-icons/fi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#EEEEEE',
      borderRadius: '30px',
      boxShadow: '0 3px 3px rgba(0,0,0,0.2)',
      padding: '10px',
      marginTop: '10px',
      fontSize: "min(3vw, 22px)",
      lineHeight: "200%",
      fontFamily: "'Poppins', sans-serif",
    },
    lnkdin: {
      width: "30px",
      height: "30px",
    },
   button: {
    backgroundColor: '#77b8bd',
    color: 'white',
    fontSize: "min(3vw, 22px)",
    padding: '10px',
    margin: '10px',
   }
})
);

interface AboutOSProps {
  navTo(): void;
}

const AboutOttoSchool: React.FC<AboutOSProps> = (props) => {
  const classes = useStyles();
  const {navTo} = props;

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className={classes.root}
      >
          We prefer servant leadership over top-down rulemaking
          <FiGitCommit/>
          Our focus is on tacit knowledge over step-by-step processes
          <FiGitCommit/>
          We beleive skill*discipline=strenght: everyone has the chance to
          become respected leader
          <FiGitCommit/>
          We ask "Why?" constantly to discover root cause instead of only
          staying on sympthom level
          <FiGitCommit/>
          <b>OttoSchool portfolio offers:</b>
          <FiChevronDown/>
          1:1 or 1:N mentoring, training for agile teams or team members
          <FiChevronDown/>
          how to set up an agile team and environment
          <FiChevronDown/>
          agile software engineering including leadership and testing
          <FiChevronDown/>
          React based website development
        <div>
          <a href="https://www.linkedin.com/services/page/0a8a4a31080a313205/"><img src={lnkdpic} alt="LinkedIn" className={classes.lnkdin} /></a>
          &nbsp;
          &nbsp;
          &nbsp;
          &nbsp;
          <a href="https://www.instagram.com/ottoschool/"><img src={insta} alt="Instagram" className={classes.lnkdin} /></a>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        key={Math.random()}
      >
        <Grid item>
          <Button className={classes.button} onClick={() => { navTo() }}>About Otto<FiChevronsDown/></Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AboutOttoSchool;