import { createTheme, ThemeOptions } from '@mui/material/styles';
const colors = {
    black: "#000000",
    green: "#77b8bd"
  };

export function createAppTheme(options: ThemeOptions) {
    return createTheme({
        typography: {
            // fontFamily: ['Poppins'].join(', '),
            fontFamily: "'Poppins', sans-serif",
            h1: {
              fontSize: "min(3.5vw, 22px)",
              fontWeight: 600,
            },
            h2: {
              fontSize: '18px',
            },
            h3: {
              fontSize: '16px',
              fontWeight: 'bold',
            },
            h4: {
              fontSize: '16px',
              textTransform: 'uppercase',
            },
            h5: {
              fontSize: '12px',
            },
            body1: {
              fontSize: '15px',
              lineHeight: '16px',
            },
            body2: {
              fontSize: '12px',
            },
            button: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
    palette: {
        primary: {
            main: colors.black,
        },
        secondary: {
            main: colors.green,
        }
    },
    ...options,
});
}

export default createAppTheme({});
