import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import calendarPicture from "./calendar.png";
import { Grid, Theme } from "@mui/material";
import { openPopupWidget } from "react-calendly";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        fontSize: '20px',
        color: '#FFFFFF'
      },
      button1: {
        height: '300px',
        width: '300px',
      },
      calendarPicture: {
        opacity: 0.8,
        "&:hover": {
          opacity: 1,
          cursor: 'pointer',
          transform: 'scale(1.1)',
          transition: 'all ease 800ms',
        },
      }
    })
);
const Calendly: React.FC = () => {
    const classes = useStyles();
    const url = "https://calendly.com/ottoschool?primary_color=77b8bd";

    return (
      <React.Fragment>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
          <img alt="calendarPicture" className={classes.calendarPicture} src={calendarPicture} onClick={() => {openPopupWidget({url})}} />     
          </Grid>
        </Grid>
        {
        //   <InlineWidget
        //   url={url}
        //   styles={{
        //   height: '300px'
        //   }}
        //   pageSettings={{
        //     backgroundColor: 'ffffff',
        //     hideEventTypeDetails: false,
        //     hideLandingPageDetails: false,
        //     primaryColor: '77b8bd',
        //     textColor: '4d5055'
        //   }}
        // />
        }
      </React.Fragment>
    );
};

export default Calendly;

