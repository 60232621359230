import React, { FC } from 'react';
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      loaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      customLoader: {
        border: '3px solid #f1f1f1',
        borderTop: '3px solid #00d1b2',
        borderRadius: '50%',
        width: '120px',
        height: '120px',
        animation: 'spin 1s linear infinite',
        transform: 'rotate(360deg)',
      }
  }));

const Loader: FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.loaderWrapper}>
            <div className={classes.customLoader}></div>
        </div>
    );
}

export default Loader;