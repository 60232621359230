import React from "react";
import ProjectManagerSectionNavbar from "./ProjectManagerSectionNavbar";
import Section from "../../Section";
// import dummyText from "../../../DummyText";

interface ProjectManagerProps {}

const ProjectManager: React.FC<ProjectManagerProps> = () => {

  const title = "Coming soon...";
  const id = "projectmanager-section";

    return (
      <div>
      {/* <ProjectManagerSectionNavbar /> */}
      <Section
        title={title}
        subtitle="...a PM questioner"
        dark={true}
        id={id+"1"}
        isLastItem={false}
      />
      {/* <Section
        title={title+" 2"}
        subtitle={dummyText}
        dark={false}
        id={id+"2"}
        isLastItem={false}
      />
      <Section
        title={title+" 3"}
        subtitle={dummyText}
        dark={true}
        id={id+"3"}
        isLastItem={false}
      />
      <Section
        title={title+" 4"}
        subtitle={dummyText}
        dark={false}
        id={id+"4"}
        isLastItem={false}
      />
      <Section
        title={title+" 5"}
        subtitle={dummyText}
        dark={true}
        id={id+"5"}
        isLastItem={true}
      /> */}
    </div>
    )
}

export default ProjectManager;

