import { Button, Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import lnkdpic from './Linkedinblackvector.png';
import otto from './otto.jpg';
import { FiGitCommit } from "react-icons/fi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#EEEEEE",
      borderRadius: "30px",
      boxShadow: "0 3px 3px rgba(0,0,0,0.2)",
      padding: "10px",
      marginTop: "10px",
      color: "#000000",
      fontSize: "min(3vw, 22px)",
      fontFamily: "'Poppins', sans-serif",
      lineHeight: "200%",
    },
    lnkdin: {
      width: "30px",
      height: "30px",
    },
    button: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: "min(3vw, 22px)",
      padding: '10px',
      margin: '10px',
     },
     picture: {
      maxWidth: "350px",
      width: "100%",
      height: "auto",
      borderRadius: "30px",
     }
  })
);

const AboutOtto = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        className={classes.root}
      >
        10 years work experience in research and development
        <FiGitCommit/>Developer, Scrum Master and Project Manager experience
        <FiGitCommit/>Scrum Trainer
        <FiGitCommit/>Mentor for Scrum Masters
        <FiGitCommit/>Certified PMP, Prince 2, Itil 4.0
        <p>
          <a href="https://www.linkedin.com/in/ottocsanyi/"><img src={lnkdpic} alt="LinkedIn" className={classes.lnkdin} /></a>
        </p>
        <img src={otto} alt="Otto" className={classes.picture}/>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        key={Math.random()}
      >
        <Grid item>
          <Button 
            className={classes.button} 
            href="https://www.linkedin.com/services/page/0a8a4a31080a313205">
              Work Together
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AboutOtto;
