import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import theme from './components/ui/Theme';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/ui/pages/Home/Home';
// import Leadership from './components/ui/pages/Leadership/Leadership';
import ScrumMaster from './components/ui/pages/ScrumMaster/ScrumMaster';
import ProjectManager from './components/ui/pages/ProjectManager/ProjectManager';
// import About from './components/ui/pages/About/About';
import Private from './components/ui/pages/Private/Private';
import Blog from './components/ui/pages/Blog/Blog';
import SignUp from './components/auth/authPages/SignUp';
import SignIn from './components/auth/authPages/SignIn';
import ForgotPassword from './components/auth/authPages/ForgotPassword';
import Dashboard from './components/auth/authPages/Dashboard';
import PublicRoute from './components/auth/authRoutePages/PublicRoute';
import PrivateRoute from './components/auth/authRoutePages/PrivateRoute';
import Loader from './components/auth/authUiComponents/Loader';
import firebase from './firebase/config';
import { getUserById, setLoading, setNeedVerification } from './store/actions/authActions';
import { RootState } from './store';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const App: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.auth);

   // Check if user exists
   useEffect(() => {
    dispatch(setLoading(true));
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
            dispatch(setLoading(true));
            await dispatch(getUserById(user.uid));
            if (!user.emailVerified) {
                dispatch(setNeedVerification());
            }
        }
        dispatch(setLoading(false));
    });
    return () => {
        unsubscribe();
    }
}, [dispatch]);

if (loading) {
    return <Loader />
}

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route exact path="/" component={() => <div style={{height: '4500px'}}><Home/></div>} />
            {/* <Route exact path="/leadership" component={() => <div style={{height: '4500px'}}><Leadership/></div>} /> */}
            <Route exact path="/scrummaster" component={() => <div style={{height: '4500px'}}><ScrumMaster/></div>} />
            <Route exact path="/projectmanager" component={() => <div style={{height: '4500px'}}><ProjectManager/></div>} />
            <Route exact path="/private" component={() => <div style={{height: '4500px'}}><Private/></div>} />
            <Route exact path="/blog" component={() => <div style={{height: '8300px'}}><Blog/></div>} />
            <Route exact path="/blog/blog-section1" component={() => <div style={{height: '8300px'}}><Blog section="blog-section1"/></div>} />
            <Route exact path="/blog/blog-section2" component={() => <div style={{height: '8300px'}}><Blog section="blog-section2"/></div>} />
            <Route exact path="/blog/blog-section3" component={() => <div style={{height: '8300px'}}><Blog section="blog-section3"/></div>} />
            <Route exact path="/blog/blog-section4" component={() => <div style={{height: '8300px'}}><Blog section="blog-section4"/></div>} />
            <Route exact path="/blog/blog-section5" component={() => <div style={{height: '8300px'}}><Blog section="blog-section5"/></div>} />
            {/* <Route exact path="/about" component={() => <div style={{height: '4500px'}}><About/></div>} /> */}
            <PublicRoute exact path="/signup" component={() => <div style={{height: '4500px'}}><SignUp/></div>} />
            <PublicRoute exact path="/signin" component={() => <div style={{height: '4500px'}}><SignIn/></div>} />
            <PublicRoute exact path="/forgot-password" component={() => <div style={{height: '4500px'}}><ForgotPassword/></div>} />
            <PrivateRoute exact path="/dashboard" component={() => <div style={{height: '4500px'}}><Dashboard/></div>} />
          </Switch>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
