import React, { FC, useState, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../authUiComponents/Message';
import Avatar from '@mui/material/Avatar';
import Button from '../authUiComponents/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { signin, setError } from '../../../store/actions/authActions';
import { RootState } from '../../../store';
import { Link } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="/">
        OttoSchool
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '150px',
    },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#77b8bd',
    color: '#fff',
    width: '100%',
    marginBottom: '30px',
  },
  link: {
    fontSize: '16px',
  },
}));

const SignIn: FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { error } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
      return () => {
          if (error) {
              dispatch(setError(''));
          }
      }
  }, [error, dispatch]);

  const submitHandler = (e: FormEvent) => {
      e.preventDefault();
      if (error) {
        dispatch(setError(''));
      }
      setLoading(true);
      dispatch(signin({ email, password }, () => setLoading(false)));
  }

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h2" variant="h2">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={submitHandler}>
            {error && <Message type="danger" msg={error} />}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="Email address"
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            label="Password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            placeholder="Password"
            autoComplete="password"
          />
          <Button
            type="submit"
            className={classes.submit}
            text={loading ? "Loading..." : "Sign In"}
            disabled={loading}
          >
            Sign In
          </Button>
          <Grid container justifyContent="space-between">
              <Grid item>
            <Link className={classes.link} to="/forgot-password">Forgot Password ?</Link>
            </Grid>
            <Grid item>
            <Link className={classes.link} to="/signup">Don't have an account? Sign Up!</Link>
            </Grid>        
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignIn;