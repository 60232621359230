import React from 'react';
import {
  Grid,
  Theme,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { grey } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlogText from './BlogText';

const useStyles = (isMobileView: boolean) => makeStyles((theme: Theme) =>
  createStyles({
    mobile: {
      maxWidth: '600px',
  },
    desktop: {
      minWidth: '600px',
      maxWidth: '1200px',
  },
    blogtext: {
      fontFamily: "'Poppins', sans-serif",
  },
    media: {
      width: `${isMobileView ? '250px' : '1200px'}`,
      paddingTop: '30%'
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: grey[500],
    },
  }));

interface BlogComponentProps {
  title: string;
//  text: string;
  image: string;
  date: string;
  imageText: string;
  isMobileView: boolean;
}

const BlogComponent: React.FC<BlogComponentProps> = (props) => {
  //  const { title, text, date, image, imageText } = props;
    const { title, date, image, imageText, isMobileView } = props;
    const classes = useStyles(isMobileView)();
    //true is set so in case of 1-2 blogs they should be auto exanded
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
      setExpanded(!expanded);
  };
 

  return (
    <Grid
      container
      justifyContent="center"
      className={clsx({
      [classes.mobile]: isMobileView,
      [classes.desktop]: !isMobileView,
    })}>
      <Card>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            Otto
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={date}
      />
      <CardMedia
        className={classes.media}
        image={image}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {imageText}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="like" size="large">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share" size="large">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="large">
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.blogtext}>
          <Typography paragraph>
          </Typography>
          <BlogText></BlogText>
        </CardContent>
      </Collapse>
    </Card>
  </Grid>
  );
}

export default BlogComponent;
