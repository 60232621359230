import React, { FC, ButtonHTMLAttributes} from 'react';
import Button from '@mui/material/Button';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    className?: string;
}

const ButtonComponent: FC<ButtonProps> = ({ text, className, onClick, type, disabled }) => {
    return (
       <Button type={type} className={`button ${className}`} onClick={onClick} disabled={disabled}>{text}</Button> 
    );
}

export default ButtonComponent;