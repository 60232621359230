import React, { useEffect } from "react";
import BlogSectionNavbar from "./BlogSectionNavbar";
import Section from "../../Section";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { animateScroll as scroll } from "react-scroll";
import clsx from 'clsx';
import IsMobileView from "src/utils/isMobileView";
import ScrollableSection from 'react-update-url-on-scroll';
import BlogComponent from '../../../blog/BlogComponent';
import image1 from '../../../blog/pics/postcovidagile.png';
// import image2 from '../../../blog/pics/help.jpg';
// import image3 from '../../../blog/pics/hero.png';
// import image4 from '../../../blog/pics/team.jpg';
// import image5 from '../../../blog/pics/hand.jpg';
// import dummyText from "../../../DummyText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {
        maxWidth: '600px',
    },
    desktop: {
        minWidth: '600px',
    },
  }));

interface BlogProps {
    section?: string;
}

const Blog: React.FC<BlogProps> = (props) => {
  const classes = useStyles();
  const { section } = props;
  const title = "Blog Section";
  const id = "blog-section";
  const isMobile = IsMobileView();
  
  useEffect(() => {
    if (section) {
      switch(section) {
        case 'blog-section1': 
          scroll.scrollTo(0);
          break;
          case 'blog-section2': 
          scroll.scrollTo(1800);
          break;
          case 'blog-section3': 
          scroll.scrollTo(3600);
          break;
          case 'blog-section4': 
          scroll.scrollTo(5400);
          break;
          case 'blog-section5': 
          scroll.scrollTo(7200);
          break;
      }
    }
  });

    return (
      <div className={clsx({
        [classes.mobile]: IsMobileView(),
        [classes.desktop]: !IsMobileView(),
      })}>
      {!isMobile && (<BlogSectionNavbar />)}
      <ScrollableSection name={id+"1"}>
        <Section
            title={"Home&Office Agile hints"}
            component={
            <BlogComponent
                title={'A fiction of how development teams work in a Hybrid work schedule and space'}
                image={image1}
                date={'2021.11.01.'}
                imageText={'Pandemic changes classical team calendars'}
                isMobileView={IsMobileView()}
            />}
            dark={true}
            id={id+"1"}
            isFirstItem
            isLastItem={false}
            isBlog={true}
        />
        </ScrollableSection>
        {/* <ScrollableSection name={id+"2"}>
        <Section
            title={title+" 2"}
            component={
                <BlogComponent 
                    title={'Second blog'}
                    // text={'Second blog text' + dummyText}
                    image={image2}
                    date={'2021.01.14.'}
                    imageText={'Second image text'}
                    isMobileView={IsMobileView()}
                />}
            dark={false}
            id={id+"2"}
            isLastItem={false}
            isBlog={true}
        />
        </ScrollableSection>
        <ScrollableSection name={id+"3"}>
        <Section
            title={title+" 3"}
            component={
                <BlogComponent 
                    title={'Third blog'}
                    // text={'Third blog text' + dummyText}
                    image={image3}
                    date={'2021.01.14.'}
                    imageText={'Third image text'}
                    isMobileView={IsMobileView()}
                />}
            dark={true}
            id={id+"3"}
            isLastItem={false}
            isBlog={true}
        />
        </ScrollableSection>
        <ScrollableSection name={id+"4"}>
        <Section
        title={title+" 4"}
        component={
            <BlogComponent 
                title={'Fourth blog'}
                // text={'Fourth blog text' + dummyText}
                image={image4}
                date={'2021.01.14.'}
                imageText={'Fourth image text'}
                isMobileView={IsMobileView()}
            />}
        dark={false}
        id={id+"4"}
        isLastItem={false}
        isBlog={true}
        />
        </ScrollableSection>
        <ScrollableSection name={id+"5"}>
        <Section
        title={title+" 5"}
        component={
            <BlogComponent 
                title={'Fifth blog'}
                // text={'Fifth blog text' + dummyText}
                image={image5}
                date={'2021.01.14.'}
                imageText={'Fifth image text'}
                isMobileView={IsMobileView()}
            />}
        dark={true}
        id={id+"5"}
        isLastItem={false}
        isBlog={true}
        />
        </ScrollableSection> */}
    </div>
    )
}

export default Blog;
