import React from "react";
import acalendar from '../blog/pics/acalendar3.png';

const BlogText = () => {

    return (
        <div>
            <p><h2>Intro</h2></p>

            <p>Pandemic times showed all-remote is not a science fiction of GitLab and other companies in software industry. They went full HO well before virus outbreak.</p>

            <p>I also tend to push for more flexibility (space and work) 2-3 years ago at whichever team I worked with. Referring to all-remote tech companies.</p>

            <p>However this blog post is not about to discuss if full remote is the way forward. I don’t even need to refer blog posts, stories about that.</p>

            <p>This blog post also won’t create a poll or pros-cons list of working from home versus office.</p>

            <p>Usually those polls in a development team shows that there are different preferences of individuals. How surprising? The average is ~1-2 days preferred in the office per week (~per Sprint). No brainer to calculate if you assume there are people who want to stay at home and who want to be in the office, plus there are people somewhere in the middle.</p>

            <p>So let’s calculate with a hybrid approach. 1-2 days in the office per (2 weeks long) Sprint.</p>

            <p><u>This blog post collects suggested and non-suggested activities on office and non-office days.</u></p>

            <p><h3>Terms to declare before reading forward...</h3></p>

            <p><h3>4C</h3></p>
            <p>A mixture of Consult, Collaborate, Critical Case.
                <br/>
                I.e.:
                <li>Consult with colleagues out of the team</li>
                <li>Have 1:1</li>
                <li>Pair programming</li>
                <li>If there is an urgent case you should take time and solve it</li>
            </p>

            <p><h3>Challenge registry</h3></p>
            <p>Improve team knowledge via challenge registry.
                <br />Not an impediment collection list.
                <br />Instead if you met something interesting for the group since last meeting, bring it in.<br /> Let it be tech, fun, work etc. related. Vote on it and discuss top items.
                <p>Stay tuned, OttoSchool will post the process of this registry soon.</p></p>

            <p><h2>Prereq steps</h2></p>

            <p>
                <li>Drop the schedule of strict n. weekly agile meetings if exists</li>
                <li>Plan the office day/days with the teams</li>
                <li>Identify the frequency of the retrospect time (every second week, every release etc.) based on team feedback</li>
                <li>Identify the frequency of challenge registry (link) read event</li>
                <li>Any team member can be invited to any of these activities on Agile max days</li>
            </p>

            <p><h2>Agile max days</h2></p>
            <h3>DOs</h3>
            <p>
                <li>Practice agile values as much as possible</li>
                <li>Energize and build the team</li>
                <li>Reflect on what went well and what could be better contributing to team velocity</li>
                <li>Improve team knowledge via challenge registry</li>
                <li>Refine the product backlog on week A do the (takt) planning on week B</li>
                <li>Ensure 4C time</li>
            </p>
            <p>
                <h3>DON’Ts</h3>
                <li>Time your implementation task that you can do alone</li>
                <li>Do daily standup (only if you are having a strict Scrum and it's not a Sprint closing and starting day. Otherwise you will have the chance to look at taskboard)</li>
                <li>Do long review meetings with virtual stakeholders. It can go full virtual</li>
            </p>
            <p>
                <img src={acalendar}/>
                <br />An example of how an agile max day can look like in your calendar
            </p>
            <p><h2>Implementation at home days</h2></p>
            <p>
                <h3>DOs</h3>
                <li>Implement the assigned tasks</li>
                <li>Resolve blockers quickly by reaching out to colleagues virtually</li>
                <li>Be open minded and collect non-blocker (low prio but interesting) architectural, code related challenges that can be shared with team on agile max days</li>
            </p>
            <p>
                <h3>DON’Ts</h3>
                <li>Wait with blockers until max agile days</li>
            </p>
        </div>
    )
}

export default BlogText;

