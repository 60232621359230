import React from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link, animateScroll as scroll } from "react-scroll";
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      backgroundColor: "#000000",
      position: "fixed",
      top: "105px",
      width: "100%",
      height: "70px",
      zIndex: 1000,
      boxShadow: "0 4px 14px 0 rgba(0, 0, 0, 0.15)",
    },
    navContent: {
      padding: "0rem 3rem",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    arrowUp: {
      height: "60px",
      width: "60px",
      '&:hover': {
        cursor: "pointer",
      },
      color: "#77b8bd",
    },
    navItem: {
      display: "inline",
      marginLeft: "2rem",
      color: "#ffffff",
      '&:hover': {
        cursor: "pointer",
        color: "#77b8bd",
      },
      fontFamily: "'Poppins', sans-serif",
    },
    active: {
      borderBottom: "1px inset #77b8bd",
      paddingBottom: "5px",
    },
  }));

interface ScrumMasterSectionNavbarProps {}

const ScrumMasterSectionNavbar: React.FC<ScrumMasterSectionNavbarProps> = () => {
  const classes = useStyles();

// useEffect(() => {
//   scroll.scrollToTop();
// });

  return (
    <nav className={classes.nav}>
      <div className={classes.navContent}>
        <ArrowDropUpIcon
          className={classes.arrowUp}
          onClick={scroll.scrollToTop}
        />
        <ul>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section1"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              1
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              2
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section3"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              3
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section4"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              4
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section5"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              5
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section6"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              6
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section7"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              7
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section8"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              8
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section9"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              9
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section10"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              10
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section11"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              11
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section12"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              12
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section13"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Result
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section14"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Reserve Mentor
            </Link>
          </li>
          <li className={classes.navItem}>
            <Link
              activeClass={classes.active}
              to="scrummaster-section15"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Regular Mentoring
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default ScrumMasterSectionNavbar;

