import React, { useState } from 'react';
import IsMobileView from "src/utils/isMobileView";
import { Box, Theme, Typography, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import AdjustIcon from '@mui/icons-material/Adjust';

const StyledRating = withStyles({
  iconFilled: {
    color: 'black',
  },
  iconHover: {
    color: 'gray',
  },

})(Rating);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      auto: {
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center'
      },
      questionContainer: {
        padding: '20px',
      },
      question: {
        fontSize: '20px',
        fontWeight: 'bold',
      },
  }));

interface CustomRatingComponentProps {
  question: string;
  questionID: number;
  onAnswer(id: number, answer: number | null): void;
  navTo(elementId: number): void;
}

const CustomRatingComponent: React.FC<CustomRatingComponentProps> = (props) => {
    const classes = useStyles();
    const { question, questionID, onAnswer, navTo } = props;
    const [value, setValue] = useState<number | null>(null);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const isMobile = IsMobileView();

    const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
      1: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Very Very Dissatisfied',
      },
      2: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Very Dissatisfied',
      },
      3: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Dissatisfied',
      },
      4: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Neutral',
      },
      5: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Satisfied',
      },
      6: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Very Satisfied',
      },
      7: {
        icon: <AdjustIcon style={{ fontSize: isMobile ? 30 : 45 }} />,
        label: 'Very Very Satisfied',
      },
    };

    function IconContainer(props: IconContainerProps) {
      const { value, ...other } = props;
      return <span {...other}>{customIcons[value].icon}</span>;
    }

  return (
    <React.Fragment>
      <Grid container justifyContent="center" className={classes.questionContainer}>
        <Typography className={classes.question}>{question}</Typography>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Grid item>
          <Typography>False</Typography>
        </Grid>
        <Grid item>
          <Box>
              <StyledRating
                name={String(questionID)}
                className={classes.auto}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue)
                  onAnswer(questionID, newValue);
                  navTo(questionID);
                  setReadOnly(true);
                }}
                readOnly={readOnly}
                precision={1}
                IconContainerComponent={IconContainer}
                max={7}
                getLabelText={(value: number) => customIcons[value].label}
              />
            </Box>
          </Grid>
      <Grid item>
        <Typography>True</Typography>
      </Grid>
    </Grid>
  </React.Fragment>
  );
}

export default CustomRatingComponent;
