import React, { useEffect } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Link, animateScroll as scroll } from "react-scroll";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ScrollableLink } from 'react-update-url-on-scroll';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      backgroundColor: "#000000",
      position: "fixed",
      top: "105px",
      width: "100%",
      height: "70px",
      zIndex: 1000,
      boxShadow: "0 4px 14px 0 rgba(0, 0, 0, 0.15)",
    },
    navContent: {
      padding: "0rem 3rem",
      margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    arrowUp: {
      height: "60px",
      width: "60px",
      '&:hover': {
        cursor: "pointer",
      },
      color: "#77b8bd",
    },
    navItem: {
      display: "inline",
      marginLeft: "2rem",
      color: "#ffffff",
      '&:hover': {
        cursor: "pointer",
        color: "#77b8bd",
      },
      fontFamily: "'Poppins', sans-serif",
    },
    active: {
      borderBottom: "1px inset #77b8bd",
      paddingBottom: "5px",
    },
  }));

interface BlogSectionNavbarProps {}

const BlogSectionNavbar: React.FC<BlogSectionNavbarProps> = () => {
  const classes = useStyles();

useEffect(() => {
  scroll.scrollToTop();
});

  return (
    <nav className={classes.nav}>
      <div className={classes.navContent}>
      <ArrowDropUpIcon className={classes.arrowUp} onClick={scroll.scrollToTop}/>
        <ul>
          <li className={classes.navItem}>
          <ScrollableLink href="/blog-section1">
            <Link
              activeClass={classes.active}
              to="blog-section1"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Home&Office Agile hints
            </Link>
            </ScrollableLink>
          </li>
          {/* <li className={classes.navItem}>
          <ScrollableLink href="/blog-section2">
            <Link
              activeClass={classes.active}
              to="blog-section2"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Blog Section 2
            </Link>
            </ScrollableLink>
          </li>
          <li className={classes.navItem}>
          <ScrollableLink href="/blog-section3">
            <Link
              activeClass={classes.active}
              to="blog-section3"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Blog Section 3
            </Link>
            </ScrollableLink>
          </li>
          <li className={classes.navItem}>
          <ScrollableLink href="/blog-section4">
            <Link
              activeClass={classes.active}
              to="blog-section4"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Blog Section 4
            </Link>
            </ScrollableLink>
          </li>
          <li className={classes.navItem}>
          <ScrollableLink href="/blog-section5">
            <Link
              activeClass={classes.active}
              to="blog-section5"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Blog Section 5
            </Link>
            </ScrollableLink>
          </li> */}
        </ul>
      </div>
    </nav>
  );
}

export default BlogSectionNavbar;

