import React from "react";
import { Theme, Button, Typography, Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import { animateScroll as scroll } from "react-scroll";
// import { classicNameResolver } from "typescript";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    backgroundColor: '#EEEEEE',
    borderRadius: '30px',
    boxShadow: '0 3px 3px rgba(0,0,0,0.2)',
    padding: '20px',
    marginTop: '14px',
  },
  desc: {
    margin: '10px',
    fontSize: '17px',
    color: '#000000',
  },
  typo: {
    padding: '16px',
    fontSize: '20px',
  },
  button: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: '15px',
    padding: '10px',
    margin: '10px',
   },
  send: {
    backgroundColor: '#77b8bd',
    color: 'white',
  }
})
);

const RegularMentoring = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Typography className={classes.desc}>
            If you would like to access regular mentoring in order to improve your skills, let us know via
            {" "}
            <a href="mailto:hello@ottoschool.org">hello@ottoschool.org</a>
          </Typography>
        </Grid>
        <Grid item>
          <Button className={classes.send} href="mailto:hello@ottoschool.org">Contact Us</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RegularMentoring;
