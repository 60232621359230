import { Theme, Grid, Button } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import "video-react/dist/video-react.css";
import React from "react";
import { Player } from 'video-react';
import welcomevid from "./simpleshowOS.mp4";
import postervid from './welcometoOS.png';
import IsMobileView from "src/utils/isMobileView";
import { FiChevronsDown } from "react-icons/fi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videostyle: {
      position: 'relative',
    },
    /* iframestyle: {
      left: '40',
      height: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '500px',
        height: '300px'
      },
      [theme.breakpoints.up('sm')]: {
        width: '700px',
      },
      [theme.breakpoints.up('md')]: {
        width: '1000px',
      },
    }, */
    button: {
      backgroundColor: 'white',
      color: 'black',
      fontSize: "min(3vw, 22px)",
      padding: '10px',
      margin: '10px',
     },
  })
);


interface WelcomeVideoProps {
  navTo(): void;
}

const WelcomeVideo: React.FC<WelcomeVideoProps> = (props) => {
    const classes = useStyles();
    const {navTo} = props;
    
    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item>
            <Player
              src={welcomevid}
              poster={postervid}
              fluid={false}
              playsInline
              width={IsMobileView() ? 334 : 628}
              height={IsMobileView() ? 150 : 309}
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => { navTo() }}
            >
              About OttoSchool<FiChevronsDown/>
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
}

export default WelcomeVideo;