import React from "react";
import clsx from 'clsx';
import { Theme } from "@mui/material";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      background: "white",
    },
    sectionDark: {
      background: "#77b8bd",
      color: "white",
      // marginTop: "80px",
    },
    lastSection: {
      background: "white",
      height: "1500px",
    },
    sectionContent: {
      fontSize: 18,
      margin: "0 auto",
      padding: "95px 40px 95px",
      // height: "400px",
    },
    sectionContentFirstItem: {
      fontSize: 18,
      marginTop: "175px",
      padding: "95px 40px 95px",
      // height: "400px",
    },
    sectionContentFirstItemWithoutNavBar: {
      fontSize: 18,
      marginTop: "118px",
      padding: "95px 40px 95px",
      // height: "400px",
    },
    sectionContentBlog: {
      fontSize: 18,
      margin: "0 auto",
      padding: "95px 40px 95px",
      height: "1500px",
    },
    sectionContentBlogFirstItem: {
      fontSize: 18,
      marginTop: "100px",
      padding: "95px 40px 95px",
      height: "4000px",
    },
    sectionTitle: {
      margin: "34px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "min(4vw, 30px)",
    },
    responsive: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '600px',
        // minHeight: '800px'
      },
      [theme.breakpoints.up('sm')]: {
        minwidth: '600px',
        // minHeight: '500px'
      },
      [theme.breakpoints.up('md')]: {
        minWidth: '1000px',
        // minHeight: '500px'
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: '1300px',
        // minHeight: '1300px'
      },
    }
  }));

interface SectionProps {
  title: string,
  subtitle?: string,
  dark: boolean,
  id: string,
  isLastItem?: boolean,
  isFirstItem?: boolean,
  isFirstItemWithoutNavBar?: boolean;
  component?: JSX.Element,
  isBlog?: boolean,
}

const Section: React.FC<SectionProps> = (props) => {
const { title, subtitle, dark, id, isLastItem, isFirstItem, component, isBlog, isFirstItemWithoutNavBar } = props
const classes = useStyles();

  return (
    <div className={clsx({
      [classes.responsive]: true,
      [classes.section]: isLastItem ? false : true,
      [classes.sectionDark]: isLastItem ? false : dark,
      [classes.lastSection]: isLastItem,
    })}>
      <div className={clsx({
      [classes.sectionContent]: isFirstItem ? false : true,
      [classes.sectionContentFirstItem]: isFirstItem ? true : false,
      [classes.sectionContentBlog]: isBlog ? true : false,
      [classes.sectionContentBlogFirstItem]: isBlog ? true : false,
      [classes.lastSection]: isLastItem,
      [classes.sectionContentFirstItemWithoutNavBar]: isFirstItemWithoutNavBar,
    })} 
      id={id}>
        <h1 className={classes.sectionTitle}>{title}</h1>
        <p>{subtitle}</p>
        <div>{component}</div>
      </div>
    </div>
  );
}

export default Section;
