import React from "react";

const PrivateBlog1 = () => {
  return (
    <div>
      <h2>
        Here comes something out of the box soon...
      </h2>
    </div>
  );
};

export default PrivateBlog1;