import React from "react";
import Section from "../../Section";
import PrivateBlog1 from "../Blog/PrivatBlog1";

function Private() {
  return(
    <div>
      <Section
        title={"Private"}
        component={<PrivateBlog1/>}
        dark={true}
        id={"1"}
        isLastItem={false}
      />
    </div>
  );
}

export default Private;