import React from "react";
// import HomeSectionNavbar from "./HomeSectionNavbar";
import Section from "../../Section";
import { Element, scroller } from "react-scroll";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import CustomRatingComponent from '../../../survey/CustomRatingComponent';
// import SurveyInputName from '../../../survey/SurveyInputName';
// import Results from '../../../survey/Results';
import WelcomeVideo from "src/components/welcome/WelcomeVideo";
import AboutOttoSchool from "src/components/welcome/AboutOttoSchool";
import AboutOtto from "src/components/welcome/AboutOtto";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }));

interface HomeProps {}

// export interface Answers {
//   id: string;
//   point: number | null;
// }

const Home: React.FC<HomeProps> = (props) => {
  const classes = useStyles();
  const title = "";
  // const resultsTitle = "Results";
  const id = "home-section";
  // const questionID = "questionID";
  // const [showSections, setShowSections] = useState(false);
  // const [answers, setAnswers] = useState<Answers[]>([]);
  // const [user, setUser] = useState('');

  // const navToNextSection = () => {
  //   scroll.scrollMore(600);
  // };

  // const getUser = (name: string) => {
  //   setUser(name);
  // }
  
  // const addAnswer = (id: string, point: number | null) => {
  //   const found = answers.find(answer => answer.id === id);
  //   if (found) {
  //     setAnswers([
  //       ...answers.filter(answer => answer.id !== id),
  //       {id, point},
  //     ]);
  //   } else {
  //     setAnswers([
  //       ...answers,
  //       {
  //         id,
  //         point,
  //       },
  //     ]);
  //   }
  // }

  // console.log(answers);

   // Scroll by Element! It will scroll to next element by id
   const navToNextSection = (elementId: number) => {
    const nextElementIdStringValue = elementId + 1;
    scroller.scrollTo(id + nextElementIdStringValue, {
      spy: true,
      smooth: true,
      offset: 0,
      duration: 1000,
    });
  };

    return (
      <div className={classes.root}>
      {/* <HomeSectionNavbar /> */}
      {/* <Section
        title={title}
        component={<SurveyInputName onSubmit={() => {setShowSections(true); navToNextSection()}} getName={getUser}/>}
        dark={true}
        id={id+"1"}
        isLastItem={false}
      /> */}
      <Element name={id + "1"}>
      <Section
        title={title}
        component={<WelcomeVideo navTo={() => navToNextSection(1)}/>}
        dark={true}
        id={id+"1"}
        isLastItem={false}
        isFirstItemWithoutNavBar
      />
      </Element>
      <Element name={id + "2"}>
      <Section
        title={"Mindset of OttoSchool"}
        component={<AboutOttoSchool navTo={() => navToNextSection(2)}/>}
        dark={false}
        id={id+"2"}
        isLastItem={false}
      />
      </Element>
      <Element name={id + "3"}>
      <Section
        title={"About Otto"}
        component={<AboutOtto/>}
        dark={true}
        id={id+"3"}
        isLastItem={false}
      />
      </Element>
      {/* {showSections && (
            <React.Fragment>
                 <Section
                 title={title+" 3"}
                 component={<CustomRatingComponent question={'You prefer certification based training over on the job learning.'} questionID={questionID+"2"} onAnswer={addAnswer} navTo={navToNextSection}/>}
                 dark={false}
                 id={id+"4"}
                 isLastItem={false}
               />
                <Section
                 title={resultsTitle}
                 component={<Results user={user} answers={answers}/>}
                 dark={false}
                 id={id+"7"}
                 isLastItem={false}
               />
            </React.Fragment> */}
      {/* )} */}
    </div>
    )
}

export default Home;
