import React, { useState } from "react";
import ScrumMasterSectionNavbar from "./ScrumMasterSectionNavbar";
import Section from "../../Section";
// import dummyText from "../../../DummyText";
import { Element, scroller } from "react-scroll";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import IsMobileView from "src/utils/isMobileView";
import CustomRatingComponent from '../../../survey/CustomRatingComponent';
import Results from '../../../survey/Results';
import OneTimeMentoring from "src/components/reserve/OneTimeMentoring";
import RegularMentoring from "src/components/reserve/RegularMentoring";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
  }));

interface ScrumMasterProps {}

export interface Answers {
  id: number;
  point: number | null;
}

const ScrumMaster: React.FC<ScrumMasterProps> = () => {
  const classes = useStyles();
  const title = "Question";
  const resultsTitle = "Skills Map (Beta UI)";
  const id = "scrummaster-section";
  // const [showSections, setShowSections] = useState(false);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [collab, setCollab] = useState<number>(0);
  const [facili, setFacili] = useState<number>(0);
  const [scrum, setScrum] = useState<number>(0);
  const [problems, setProblem] = useState<number>(0);
  const [protect, setProtect] = useState<number>(0);
  const [leader, setLeader] = useState<number>(0);
  const isMobile = IsMobileView();

  // Scroll by Element! It will scroll to next element by id
  const navToNextSection = (elementId: number) => {
    const nextElementIdStringValue = elementId + 1;
    scroller.scrollTo(id + nextElementIdStringValue, {
      spy: true,
      smooth: true,
      offset: -70,
      duration: 1000,
    });
  };

  // Get point value, input is the CustomRatingComponent 'dumplings' what you touch and refactor them to another value
  const getRefactoredPointValue = (answerPoint: number | null) => {
    switch (answerPoint) {
      case 1:
        return 3;
      case 2:
        return 2;
      case 3:
        return 1;
      case 4:
        return 0;
      case 5:
        return -1;
      case 6:
        return -2;
      case 7:
        return -3;
      default:
        return 0;
    }
  }

  // set collab, scrum, facili based on question id and point value
  const setCollabScrumFaciliByQuestionId = (id: number, point: number | null) => {
    console.log('id', id);
    if (point) {
    switch (id) {
      case 1:
        setCollab(collab + point);
        break;
      case 2:
        setScrum(scrum + point);
        break;
      case 3:
        setFacili(facili + point);
        break;
      case 4:
        setCollab(((collab + point)/6)*100);
        if(collab<0) {
          setCollab((collab)*0);
        }
        break;
      case 5:
        setScrum(((scrum + point)/6)*100);
        if(scrum<0) {
          setScrum((scrum)*0);
        }
        break;
      case 6:
        setFacili(((facili + point)/6)*100);
        if(facili<0) {
          setFacili((facili)*0);
        }
        break;
      case 7:
        setProblem(problems + point);
        break;    
      case 8:
        setProtect(protect + point);
        break;    
      case 9:
        setLeader(leader + point);
        break;
      case 10:
        setProblem(((problems + point)/6)*100);
        if(problems<0) {
          setProblem((problems)*0);
        }
        break;    
      case 11:
        setLeader(((leader + point)/6)*100);
        if(leader<0) {
          setLeader((leader)*0);
        }
        break;
      case 12:
        setProtect(((protect + point)/6)*100);
        if(protect<0) {
          setProtect((protect)*0);
        }
        break;       
      default:
        break;
      }
    }
  };

  const addAnswer = (id: number, point: number | null) => {
    //found used for taking the same question by id for more than once
    const found = answers.find(answer => answer.id === id);

    if (found) {
      setCollabScrumFaciliByQuestionId(id, getRefactoredPointValue(point));
      setAnswers([
        ...answers.filter(answer => answer.id !== id),
        {
          id,
          point: getRefactoredPointValue(point),
        },
      ]);
    } else {
      setCollabScrumFaciliByQuestionId(id, getRefactoredPointValue(point));
      setAnswers([
        ...answers,
        {
          id,
          point: getRefactoredPointValue(point),
        },
      ]);
    }
  }

  const sortedAnswers = (ans: Answers[]) => {
    return ans.sort(function (a, b) {
      return a.id - b.id;
    });
  }

  console.log(sortedAnswers(answers));

    return (
      <div className={classes.root}>
        {!isMobile && (<ScrumMasterSectionNavbar />)}
        <React.Fragment>
        <Element name={id + "1"}>
          <Section
            title={title + " 1"}
            component={
              <CustomRatingComponent
                question={
                  "You prefer finishing with individual task over spreading knowledge around the team"
                }
                questionID={1}
                onAnswer={addAnswer}
                navTo={navToNextSection}
              />
            }
            dark={false}
            id={id + "1"}
            isLastItem={false}
            isFirstItemWithoutNavBar
          />
        </Element>
        <Element name={id + "2"}>
          <Section
            title={title + " 2"}
            component={
              <CustomRatingComponent
                question={"You could NOT explain with confidence what release backlog refinement means"}
                questionID={2}
                onAnswer={addAnswer}
                navTo={navToNextSection}
              />
            }
            dark={true}
            id={id + "2"}
            isLastItem={false}
          />
          </Element>
          <Element name={id + "3"}>
            <Section
              title={title + " 3"}
              component={
                <CustomRatingComponent
                  question={"You feel shy when talking in public to 2+ people"}
                  questionID={3}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={false}
              id={id + "3"}
              isLastItem={false}
            />
          </Element>
          <Element name={id + "4"}>
            <Section
              title={title + " 4"}
              component={
                <CustomRatingComponent
                  question={
                    "You support decision by the book over on hands experience"
                  }
                  questionID={4}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={true}
              id={id + "4"}
              isLastItem={false}
            />
           </Element>
           <Element name={id + "5"}>
            <Section
              title={title + " 5"}
              component={
                <CustomRatingComponent
                  question={
                    "You would NOT lead a Scrum to ScrumBan transformation with confidence"
                  }
                  questionID={5}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={false}
              id={id + "5"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "6"}>
            <Section
              title={title + " 6"}
              component={
                <CustomRatingComponent
                  question={
                    "As a facilitator, you would listen to long stories during a daily stand up"
                  }
                  questionID={6}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={true}
              id={id + "6"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "7"}>
            <Section
              title={title + " 7"}
              component={
                <CustomRatingComponent
                  question={
                    "You often play safety; let others try out new things first"
                  }
                  questionID={7}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={false}
              id={id + "7"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "8"}>
            <Section
              title={title + " 8"}
              component={
                <CustomRatingComponent
                  question={
                    "You value individual goals over team goals"
                  }
                  questionID={8}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={true}
              id={id + "8"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "9"}>
            <Section
              title={title + " 9"}
              component={
                <CustomRatingComponent
                  question={
                    "Others are usually more respected within the team"
                  }
                  questionID={9}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={false}
              id={id + "9"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "10"}>
            <Section
              title={title + " 10"}
              component={
                <CustomRatingComponent
                  question={
                    "You often rush to get more and more tasks done"
                  }
                  questionID={10}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={true}
              id={id + "10"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "11"}>
            <Section
              title={title + " 11"}
              component={
                <CustomRatingComponent
                  question={
                    "You feel your network should be improved"
                  }
                  questionID={11}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={false}
              id={id + "11"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "12"}>
            <Section
              title={title + " 12"}
              component={
                <CustomRatingComponent
                  question={
                    "Chasing others to get the job done is not something familiar to you"
                  }
                  questionID={12}
                  onAnswer={addAnswer}
                  navTo={navToNextSection}
                />
              }
              dark={true}
              id={id + "12"}
              isLastItem={false}
            /> 
          </Element>
          <Element name={id + "13"}>
            <Section
              title={resultsTitle}
              component={<Results user="tester" answers={sortedAnswers(answers)} navTo={() => navToNextSection(13)} collab={collab} scrum={scrum} facili={facili} problems={problems} protect={protect} leader={leader}/>}
              dark={false}
              id={id + "13"}
              isLastItem={false}
            />
          </Element>
          <Element name={id + "14"}>
            <Section
              title={"Reserve 1:1 with a Mentor to review your score!"}
              component={<OneTimeMentoring navTo={() => navToNextSection(14)} />}
              dark={true}
              id={id + "14"}
              isLastItem={false}
            />
          </Element>
          <Element name={id + "15"}>
            <Section
              title={"Work together on a regular basis to become a world-class Agile Leader!"}
              component={<RegularMentoring/>}
              dark={false}
              id={id + "15"}
              isLastItem={true}
            />
          </Element>
        </React.Fragment>
      </div>
    );
}

export default ScrumMaster;

