import React from "react";
import { Theme, Grid, Typography, Button } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Answers } from "../ui/pages/ScrumMaster/ScrumMaster";
import { FiChevronsDown } from "react-icons/fi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#EEEEEE',
      borderRadius: '30px',
      boxShadow: '0 3px 3px rgba(0,0,0,0.2)',
      padding: '32px',
      marginTop: '24px',
    },
    user: {
      padding: '16px',
      fontSize: '24px',
      fontWeight: 'bold',
      marginTop: '-24px',
    },
    desc: {
      marginTop: '-24px',
      fontSize: '20px',
    },
    typo: {
      padding: '16px',
      fontSize: '20px',
    },
    button: {
      backgroundColor: '#77b8bd',
      color: 'white',
      fontSize: '15px',
      padding: '10px',
      margin: '10px',
     }
  })
);

interface ResultsProps {
  user: string;
  answers: Answers[];
  navTo(): void;
  collab: number;
  facili: number;
  scrum: number;
  problems: number,
  protect: number,
  leader: number,
}

const Results: React.FC<ResultsProps> = (props) => {
  const classes = useStyles();
  const { user, answers, navTo, collab, facili, scrum, problems, protect, leader } = props;

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        className={classes.root}
      >
        <Grid item><Typography className={classes.user}>Dear {user} !</Typography></Grid>
        <Grid item><Typography className={classes.desc}>Please find your answers below...</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Collaboration:</b> {collab.toFixed(1)}%</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Scrum Competence:</b> {scrum.toFixed(1)}%</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Facilitation:</b> {facili.toFixed(1)}%</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Problem Solving:</b> {problems.toFixed(1)}%</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Team Protection:</b> {protect.toFixed(1)}%</Typography></Grid>
        <Grid item><Typography className={classes.desc}><b>Leadership:</b> {leader.toFixed(1)}%</Typography></Grid>
        {/* {answers.map((answer) => (
          
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={5}
            key={Math.random()}
          >
            <Grid item><Typography className={classes.typo}>Question: {answer.id}</Typography></Grid>
            <Grid item><Typography className={classes.typo}>Answer: {answer.point}</Typography></Grid>
          </Grid>
        ))} */}
      </Grid>
      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          key={Math.random()}
        >
      {/* <Grid item><Typography className={classes.typo}>Collaboration(1-100): {collab}%</Typography></Grid>
      <Grid item><Typography className={classes.typo}>Scrum competence(1-100): {scrum}%</Typography></Grid>
      <Grid item><Typography className={classes.typo}>Facilitiation(1-100): {facili}%</Typography></Grid>
      <p></p> */}
      <Grid item><Button className={classes.button}  
      onClick={() => {navTo()}}>Check result with a Mentor<FiChevronsDown/></Button></Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Results;

