import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Message from '../authUiComponents/Message';
import { Theme, Grid } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { setSuccess } from '../../../store/actions/authActions';
import { RootState } from '../../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        marginTop: '200px',
    }
  }));

const Dashboard: FC = () => {
    const classes = useStyles();
    const { user, needVerification, success } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success) {
            dispatch(setSuccess(''));
        }
    }, [success, dispatch]);

    return (
        <Grid container justifyContent="center" className={classes.container}>
            <Grid item>
            {needVerification && <Message type="success" msg="Please verify your email address."/>}
            <h1 className="is-size-1">Welcome {user?.firstName}</h1>
            </Grid>
    
        </Grid>
    );
}

export default Dashboard;
